import React, { useState, useEffect } from "react";
import AddNewCustomer from "../components/Customer/AddNewCustomer";
import EditCustomer from "../components/Customer/EditCustomer";
import ViewCustomer from "../components/Customer/ViewCustomer";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import NoResultsMessage from "../components/NoResultsMessage";
import ModalContainer from "../components/modal/ModalContainer";
import ModalHeader from "../components/modal/ModalHeader";
import DeleteConfirmation from "../components/modal/DeleteConfirmation";

const Customers = () => {
  const [customers, setCustomers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [currentCustomer, setCurrentCustomer] = useState(null);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);

  // Load dummy data
  useEffect(() => {
    const loadCustomers = async () => {
      try {
        const response = await fetch("/dummycustomers.json");
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setCustomers(data);
      } catch (error) {
        console.error("Error fetching customers:", error);
      }
    };

    loadCustomers();
  }, []);

  const handleAddCustomer = (newCustomer) => {
    setCustomers((prevCustomers) => [...prevCustomers, newCustomer]);
    setIsAddModalOpen(false);
  };

  const handleDeleteCustomer = () => {
    if (deleteIndex !== null) {
      const updatedCustomers = customers.filter((_, index) => index !== deleteIndex);
      setCustomers(updatedCustomers);
      setDeleteIndex(null);
    }
    setIsDeleteConfirmationOpen(false); // Close the modal
  };

  const handleEditCustomer = (index) => {
    const customerToEdit = customers[index];
    if (customerToEdit) {
      setCurrentCustomer({ ...customerToEdit, index });
      setIsEditModalOpen(true);
    }
  };

  const handleUpdateCustomer = (updatedCustomer) => {
    const updatedCustomers = [...customers];
    updatedCustomers[currentCustomer.index] = updatedCustomer;
    setCustomers(updatedCustomers);
    setIsEditModalOpen(false);
    setCurrentCustomer(null);
  };

  const handleViewCustomer = (index) => {
    setCurrentCustomer(customers[index]);
    setIsViewModalOpen(true);
  };

  const toggleAddModal = () => {
    setIsAddModalOpen(!isAddModalOpen);
  };

  const toggleEditModal = () => {
    setIsEditModalOpen(!isEditModalOpen);
    setCurrentCustomer(null);
  };

  const closeViewModal = () => setIsViewModalOpen(false);

  const openDeleteConfirmation = (index) => {
    setDeleteIndex(index);
    setIsDeleteConfirmationOpen(true);
  };

  const closeDeleteConfirmation = () => {
    setIsDeleteConfirmationOpen(false);
    setDeleteIndex(null);
  };

  const filteredCustomers = customers.filter(
    (customer) =>
      customer.customer_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      customer.city.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="h-full flex flex-col gap-6">
      {/* Header container */}
      <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between mb-6 gap-6">
        <h1 className="text-2xl font-bold text-fontColor">Customer List</h1>

        {/* Search input and Add New button container */}
        <div className="flex flex-col sm:flex-row sm:items-center gap-4 w-full sm:w-auto">
          {/* Search input */}
          <div className="relative flex-1 sm:max-w-xs">
            <SearchRoundedIcon
              className="absolute left-3 top-1/2 transform -translate-y-1/2 text-fontColor opacity-70"
              style={{ fontSize: "1.5rem" }}
            />
            <input
              type="text"
              placeholder="Search Customers..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full pl-10 pr-3 py-2 border border-gray-200 rounded-lg focus:outline-none focus:border-blue-400"
            />
          </div>

          {/* Add New button */}
          <button
            onClick={toggleAddModal}
            className="px-5 py-2 bg-actionColor text-white text-base rounded-lg flex items-center justify-center hover:bg-opacity-90 transition-colors"
          >
            <AddRoundedIcon className="mr-2" style={{ fontSize: "18px" }} />
            Add New Customer
          </button>
        </div>
      </div>

      <table className="min-w-full bg-componentBackground rounded-lg shadow-md">
        <thead>
          <tr>
            <th className="border-b border-gray-200 p-4 text-fontColor opacity-70 text-base font-semibold text-left">
              Customer Name
            </th>
            <th className="border-b border-gray-200 p-4 text-fontColor opacity-70 text-base font-semibold text-left">
              Customer ID
            </th>
            <th className="border-b border-gray-200 p-4 text-fontColor opacity-70 text-base font-semibold text-left">
              Contact Number
            </th>
            <th className="border-b border-gray-200 p-4 text-fontColor opacity-70 text-base font-semibold text-left">
              Email
            </th>

            <th className="border-b border-gray-200 p-4 text-fontColor opacity-70 text-base font-semibold text-left">
              City
            </th>

            <th className="border-b border-gray-200 p-4 text-fontColor opacity-70 text-base font-semibold text-left">
              Last Contacted
            </th>

            <th className="border-b border-gray-200 p-4 text-fontColor opacity-70 text-base font-semibold text-left">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {filteredCustomers.length > 0 ? (
            filteredCustomers.map((customer, index) => (
              <tr
                key={index}
                className="hover:bg-gray-50 border-b border-gray-200 cursor-pointer"
                onClick={() => handleViewCustomer(index)}
              >
                <td className="p-4 text-sm text-fontColor font-normal text-left">
                  {customer.customer_name}
                </td>
                <td className="p-4 text-sm text-fontColor font-normal text-left">
                  {customer.customer_id}
                </td>
                <td className="p-4 text-sm text-fontColor font-normal text-left">
                  {customer.contact_number}
                </td>
                <td className="p-4 text-sm text-fontColor font-normal text-left">
                  {customer.email}
                </td>
                <td className="p-4 text-sm text-fontColor font-normal text-left">
                  {customer.city}
                </td>
                <td className="p-4 text-sm text-fontColor font-normal text-left">
                  {customer.last_contacted}
                </td>
                <td className="p-4 flex space-x-2">
                  <div
                    className="bg-blue-50 rounded-full cursor-pointer flex items-center justify-center"
                    style={{ width: "32px", height: "32px" }}
                  >
                    <EditIcon
                      onClick={(e) => {
                        e.stopPropagation(); // Prevents the click event from propagating to the row's onClick
                        handleEditCustomer(index);
                      }}
                      className="text-blue-500 hover:text-blue-700"
                      style={{ fontSize: "18px" }}
                    />
                  </div>
                  <div
                    className="bg-red-50 rounded-full cursor-pointer flex items-center justify-center"
                    style={{ width: "32px", height: "32px" }}
                  >
                    <DeleteIcon
                      onClick={(e) => {
                        e.stopPropagation(); // Prevents the click event from propagating to the row's onClick
                        openDeleteConfirmation(index);
                      }}
                      className="text-red-500 hover:text-red-700"
                      style={{ fontSize: "18px" }}
                    />
                  </div>
                  <div
                    className="bg-gray-100 rounded-full cursor-pointer flex items-center justify-center"
                    style={{ width: "32px", height: "32px" }}
                    onClick={() => handleViewCustomer(index)}
                  >
                    <MoreVertRoundedIcon
                      className="text-gray-600 hover:text-gray-800"
                      style={{ fontSize: "18px" }}
                    />
                  </div>
                </td>
              </tr>
            ))
          ) : (
            // no result component
            <tr>
              <td colSpan="10" className="text-center py-4">
                <NoResultsMessage message="No Results Found" />
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {/* Modal for Add New Customer */}
      {isAddModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white rounded-lg p-4 w-full max-w-lg sm:w-2/3 md:w-1/2 lg:w-1/3 mx-4">
            {/* Modal header */}
            <ModalHeader title="Add New Product" onClose={toggleAddModal} />
            <ModalContainer>
              <AddNewCustomer onAdd={handleAddCustomer} />
            </ModalContainer>
          </div>
        </div>
      )}

      {/* Modal for Edit Customer */}
      {isEditModalOpen && currentCustomer && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white rounded-lg p-4 w-full max-w-lg sm:w-2/3 md:w-1/2 lg:w-1/3 mx-4">
            {/* Modal Header */}
            <ModalHeader title="Edit Product" onClose={toggleEditModal} />
            <ModalContainer>
              <EditCustomer
                currentCustomer={currentCustomer}
                onUpdate={handleUpdateCustomer}
                onClose={toggleEditModal}
              />
            </ModalContainer>
          </div>
        </div>
      )}

      {/* Viewcutomer model */}
      {isViewModalOpen && currentCustomer && (
        <ViewCustomer customer={currentCustomer} onClose={closeViewModal} />
      )}

    {/* Delete Confirmation Modal */}
    {isDeleteConfirmationOpen && (
        <DeleteConfirmation
          onConfirm={handleDeleteCustomer}
          onCancel={closeDeleteConfirmation}
        />
      )}
    </div>
  );
};

export default Customers;
