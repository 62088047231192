import React, { useState, useEffect } from "react";
import AddNewSale from "../components/sales/AddNewSale";
import EditSale from "../components/sales/EditSale";
import DeleteConfirmation from "../components/modal/DeleteConfirmation";
import ModalHeader from "../components/modal/ModalHeader";
import ModalContainer from "../components/modal/ModalContainer";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import NoResultsMessage from "../components/NoResultsMessage";

const Sales = () => {
  const [sales, setSales] = useState([]);
  const [filteredSales, setFilteredSales] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);
  const [currentSale, setCurrentSale] = useState(null);

  useEffect(() => {
    const loadSales = async () => {
      try {
        const response = await fetch("/dummysales.json");
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setSales(data);
        setFilteredSales(data);
      } catch (error) {
        console.error("Error fetching sales:", error);
      }
    };

    loadSales();
  }, []);

  useEffect(() => {
    const results = sales.filter(
      (sale) =>
        sale.customer_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        sale.product_name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredSales(results);
  }, [searchTerm, sales]);

  const handleAddSale = (newSale) => {
    setSales((prevSales) => [...prevSales, newSale]);
    setIsAddModalOpen(false); // Ensure modal closes after adding
  };

  const handleDeleteSale = () => {
    if (currentSale?.index != null) {
      setSales((prevSales) =>
        prevSales.filter((_, idx) => idx !== currentSale.index)
      );
      setIsDeleteConfirmationOpen(false); // Close confirmation modal
      setCurrentSale(null); // Reset current sale
    }
  };

  const handleEditSale = (index) => {
    const saleToEdit = sales[index];
    if (saleToEdit) {
      setCurrentSale({ ...saleToEdit, index });
      setIsEditModalOpen(true); // Open edit modal
    }
  };

  const handleUpdateSale = (updatedSale) => {
    if (currentSale?.index != null) {
      setSales((prevSales) => {
        const updatedSales = [...prevSales];
        updatedSales[currentSale.index] = updatedSale;
        return updatedSales;
      });
      setIsEditModalOpen(false); // Close modal
      setCurrentSale(null); // Reset state
    }
  };

  // Ensure consistent modal state handling
  const closeModals = () => {
    setIsAddModalOpen(false);
    setIsEditModalOpen(false);
    setIsDeleteConfirmationOpen(false);
    setCurrentSale(null);
  };

  return (
    <div className="h-full flex flex-col gap-6">
      <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between mb-6 gap-6">
        <h1 className="text-2xl font-bold text-fontColor">Sales</h1>
        <div className="flex flex-col sm:flex-row sm:items-center gap-4 w-full sm:w-auto">
          <div className="relative flex-1 sm:max-w-xs">
            <SearchRoundedIcon
              className="absolute left-3 top-1/2 transform -translate-y-1/2 text-fontColor opacity-70"
              style={{ fontSize: "1.5rem" }}
            />
            <input
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full pl-10 pr-3 py-2 border border-gray-200 rounded-lg focus:outline-none focus:border-blue-400"
            />
          </div>
          <button
            onClick={() => setIsAddModalOpen(true)}
            className="px-5 py-2 bg-actionColor text-white text-base rounded-lg flex items-center justify-center hover:bg-opacity-90 transition-colors"
          >
            <AddRoundedIcon className="mr-2" style={{ fontSize: "18px" }} />
            Add New Sale
          </button>
        </div>
      </div>

      <table className="min-w-full bg-componentBackground rounded-lg shadow-md">
        <thead>
          <tr>
            <th className="border-b border-gray-200 p-4 text-fontColor opacity-70 text-base font-semibold text-left">
              Sale ID
            </th>
            <th className="border-b border-gray-200 p-4 text-fontColor opacity-70 text-base font-semibold text-left">
              Customer Name
            </th>
            <th className="border-b border-gray-200 p-4 text-fontColor opacity-70 text-base font-semibold text-left">
              Product Name
            </th>
            <th className="border-b border-gray-200 p-4 text-fontColor opacity-70 text-base font-semibold text-left">
              Quantity
            </th>
            <th className="border-b border-gray-200 p-4 text-fontColor opacity-70 text-base font-semibold text-left">
              Unit Price
            </th>
            <th className="border-b border-gray-200 p-4 text-fontColor opacity-70 text-base font-semibold text-left">
              Sales Revenue
            </th>
            <th className="border-b border-gray-200 p-4 text-fontColor opacity-70 text-base font-semibold text-left">
              Sale Date
            </th>
            <th className="border-b border-gray-200 p-4 text-fontColor opacity-70 text-base font-semibold text-left">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {filteredSales.length > 0 ? (
            filteredSales.map((sale, index) => (
              <tr
                key={index}
                className="hover:bg-gray-50 border-b border-gray-200"
              >
                <td className="p-4 text-sm text-fontColor font-normal text-left">
                  {sale.sale_id}
                </td>
                <td className="p-4 text-sm text-fontColor font-normal text-left">
                  {sale.customer_name}
                </td>
                <td className="p-4 text-sm text-fontColor font-normal text-left">
                  {sale.product_name}
                </td>
                <td className="p-4 ml-6 mb-2 text-sm font-medium bg-[#26C0E21A] rounded-md inline-block px-1 py-1 text-center w-12 text-[#26C0E2]">
                  {sale.quantity}
                </td>
                <td className="p-4 text-sm text-fontColor font-normal text-left">
                  {!isNaN(parseFloat(sale.unit_price))
                    ? parseFloat(sale.unit_price).toFixed(2)
                    : "N/A"}
                </td>
                <td className="p-4 text-sm text-fontColor font-normal text-left">
                  ${sale.sales_revenue.toFixed(2)}
                </td>
                <td className="p-4 text-sm text-fontColor font-normal text-left">
                  {sale.sale_date}
                </td>
                <td className="p-4 flex space-x-2">
                  <div
                    className="bg-blue-50 rounded-full cursor-pointer flex items-center justify-center"
                    style={{ width: "32px", height: "32px" }}
                  >
                    <EditIcon
                      onClick={() => handleEditSale(index)}
                      className="text-blue-500 hover:text-blue-700"
                      style={{ fontSize: "18px" }}
                    />
                  </div>
                  <div
                    className="bg-red-50 rounded-full cursor-pointer flex items-center justify-center"
                    style={{ width: "32px", height: "32px" }}
                  >
                    <DeleteIcon
                      onClick={() => {
                        setCurrentSale({ index });
                        setIsDeleteConfirmationOpen(true);
                      }}
                      className="text-red-500 hover:text-red-700"
                      style={{ fontSize: "18px" }}
                    />
                  </div>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="10" className="text-center py-4">
                <NoResultsMessage message="No sales found for the search criteria." />
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {isAddModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-4 w-full max-w-lg sm:w-2/3 md:w-1/2 lg:w-1/3 mx-4">
            <ModalHeader title="Add New Sale" onClose={closeModals} />
            <ModalContainer>
              <AddNewSale onAdd={handleAddSale} />
            </ModalContainer>
          </div>
        </div>
      )}

      {isEditModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white rounded-lg p-4 w-full max-w-lg sm:w-2/3 md:w-1/2 lg:w-1/3 mx-4">
            <ModalHeader title="Edit Sale" onClose={closeModals} />
            <ModalContainer onClose={closeModals}>
              <EditSale
                sale={currentSale}
                onUpdate={handleUpdateSale} // Ensure this is passed
                onCancel={closeModals}
              />
            </ModalContainer>
          </div>
        </div>
      )}

      {isDeleteConfirmationOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <DeleteConfirmation
            onConfirm={handleDeleteSale}
            onCancel={closeModals}
          />
        </div>
      )}
    </div>
  );
};

export default Sales;
