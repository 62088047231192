import React, { useState } from "react";
import SaveAsRoundedIcon from "@mui/icons-material/SaveAsRounded";
import ModalContainer from "../modal/ModalContainer";
import Field from "../modal/Field";
import SaveButton from "../modal/SaveButton";
import ImageField from "../modal/ImageField";

const EditProduct = ({ product, onUpdate, onClose }) => {
  const [editedProduct, setEditedProduct] = useState({ ...product });
  const [error, setError] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedProduct((prev) => ({ ...prev, [name]: value }));
    setError("");
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) {
        setError("File size must be less than 5MB.");
        return;
      }
      if (!["image/jpeg", "image/png"].includes(file.type)) {
        setError("Only JPEG and PNG image formats are supported.");
        return;
      }
      const reader = new FileReader();
      reader.onload = () => {
        setEditedProduct((prev) => ({ ...prev, product_image: reader.result }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      !editedProduct.product_name ||
      !editedProduct.unit_price ||
      !editedProduct.stock_quantity
    ) {
      setError("Please fill in all required fields.");
      return;
    }
    onUpdate(editedProduct);
    onClose();
  };

  const categories = [
    "Electronics",
    "Clothing",
    "Groceries",
    "Books",
    "Home Appliances",
  ];

  return (
    <ModalContainer>
      <form onSubmit={handleSubmit} className="flex flex-col mx-4 sm:mx-4">
        <Field
          label="Product ID"
          type="text"
          name="product_id"
          value={editedProduct.product_id}
          readOnly
          className="bg-gray-100 text-gray-600"
        />
        <Field
          label="Product Name"
          name="product_name"
          value={editedProduct.product_name}
          onChange={handleChange}
          placeholder="Product Name"
          required
        />
        <Field
          label="Product Category"
          type="select"
          name="product_category"
          value={editedProduct.product_category}
          onChange={handleChange}
          placeholder="Select a Category"
          options={categories}
          required
        />

        <Field
          label="Product Description"
          type="textarea"
          name="description"
          value={editedProduct.description}
          onChange={handleChange}
          placeholder="Product Description"
        />
        <Field
          label="Unit Price"
          type="number"
          name="unit_price"
          value={editedProduct.unit_price}
          onChange={handleChange}
          placeholder="Unit Price"
          min="0"
          step="0.01"
          required
        />
        <Field
          label="Stock Quantity"
          type="number"
          name="stock_quantity"
          value={editedProduct.stock_quantity}
          onChange={handleChange}
          placeholder="Stock Quantity"
          min="0"
          required
        />
        <Field
          label="Reorder Level"
          type="number"
          name="reorder_level"
          value={editedProduct.reorder_level}
          onChange={handleChange}
          placeholder="Reorder Level"
          min="0"
        />
        <ImageField
          imageSrc={editedProduct.product_image}
          onChange={handleImageChange}
          error={error}
          inputClassName="text-fontColor opacity-90 text-xs mt-2"
          iconClassName="text-fontColor opacity-70 ml-2 mt-2"
        />

        <SaveButton
          type="submit"
          disabled={
            !editedProduct.product_name ||
            !editedProduct.product_category ||
            !editedProduct.unit_price ||
            !editedProduct.stock_quantity
          }
        >
          <SaveAsRoundedIcon
            style={{ fontSize: "20px", paddingBottom: "3px" }}
          />
          Save Changes
        </SaveButton>
      </form>
    </ModalContainer>
  );
};

export default EditProduct;
