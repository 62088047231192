import React from "react";

const UploadImage = ({ onUpload }) => {
  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    try {
      const formData = new FormData();
      formData.append("file", file);
      // Make an API call to upload the image (update this URL based on your backend)
      const response = await fetch("/api/uploadImage", {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        onUpload(data.imageUrl); // Pass the image URL back to EditProfile.js
      } else {
        console.error("Image upload failed");
      }
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  return (
    <input
      type="file"
      accept="image/*"
      onChange={handleImageChange}
      style={{ display: "none" }}
      id="upload-input"
    />
  );
};

export default UploadImage;
