import React from "react";
import ReactCountryFlag from "react-country-flag";

const CountryFlags = ({ countryCode }) => {
  const getFlag = (code) => {
    switch (code) {
      case "+94":
        return "LK"; // Sri Lanka
      case "+1":
        return "US";
      case "+44":
        return "GB"; // UK
      case "+91":
        return "IN"; // India
      default:
        return "LK"; // Default to Sri Lanka flag if no match
    }
  };

  return (
    <ReactCountryFlag
      countryCode={getFlag(countryCode)}
      svg
      style={{
        width: "1.5em", // Smaller flag size
        height: "1.5em", // Smaller flag size
      }}
      title={countryCode}
    />
  );
};

export default CountryFlags;
