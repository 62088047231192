import React from "react";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import EditNoteRoundedIcon from "@mui/icons-material/EditNoteRounded";
import CountryFlags from "../profile/CountryFlags"; //CountryFlags component

const ViewProfile = ({ toggleEditMode, onClose }) => {
  const { firstName, lastName, phoneNumber, imageUrl, countryCode } =
    JSON.parse(localStorage.getItem("user")) || {};

  return (
    <div className="p-8 h-full mx-4 bg-componentBackground space-y-6">
      {/* Header */}
      <div className="flex items-center justify-between relative">
        <button
          onClick={onClose}
          className="p-1 bg-slate-100 rounded-full hover:bg-slate-200 flex items-center justify-center"
          style={{ width: "36px", height: "36px" }} // Adjust as needed for a balanced size
        >
          <ArrowBackIosNewRoundedIcon
            className="text-gray-400"
            fontSize="small"
          />
        </button>
        <h2 className="absolute left-1/2 transform -translate-x-1/2 text-xl font-semibold text-fontColor">
          Profile Details
        </h2>
      </div>

      {/* Profile Image / Initials */}
      <div className="text-center">
        <div className="w-24 h-24 rounded-full bg-gray-200 flex items-center justify-center mx-auto overflow-hidden">
          {imageUrl ? (
            <img
              src={imageUrl}
              alt="Profile"
              className="w-full h-full object-cover"
            />
          ) : (
            <span className="text-3xl font-bold text-fontColor">
              {firstName?.[0]}
              {lastName?.[0]}
            </span>
          )}
        </div>
      </div>

      {/* User Information with Labels and Text Fields */}
      <div className=" pt-6 space-y-6">
        <div>
          <label className="block text-fontColor font-medium mb-2 text-base">
            First Name
          </label>
          <input
            type="text"
            value={firstName}
            readOnly
            className="w-full bg-gray-100 border border-gray-100 rounded p-2 text-fontColor opacity-70 font-normal text-sm"
          />
        </div>
        <div>
          <label className="block text-fontColor font-medium mb-2 text-base">
            Last Name
          </label>
          <input
            type="text"
            value={lastName}
            readOnly
            className="w-full bg-gray-100 border border-gray-100 rounded p-2 text-fontColor opacity-70 font-normal text-sm"
          />
        </div>
        <div>
          <label className="block text-fontColor font-medium mb-2 text-base">
            Phone Number
          </label>
          <div className="w-full bg-gray-100 border border-gray-100 rounded p-2 flex items-center space-x-3">
            {/* Country Flag Component */}
            <div className="flex items-center space-x-2 text-xs">
              <CountryFlags countryCode={countryCode} />
              <span className="text-sm  text-fontColor opacity-70 font-normal">
                {phoneNumber}
              </span>
            </div>
          </div>
        </div>
      </div>

      {/* Edit Button at the Bottom */}
      <div className="pt-6 text-center">
        <button
          className="w-full bg-actionColor text-white py-2 rounded-[10px] hover:bg-opacity-90 transition duration-150 text-base font-normal flex items-center justify-center gap-2"
          onClick={toggleEditMode}
        >
          <EditNoteRoundedIcon style={{ fontSize: "20px" }} />
          Edit
        </button>
      </div>
    </div>
  );
};

export default ViewProfile;
