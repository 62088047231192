import React, { useState, useRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";

const DateHeader = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  // Create refs for the start and end date pickers
  const startDatePickerRef = useRef(null);
  const endDatePickerRef = useRef(null);

  const datePickerContainerStyle = {
    backgroundColor: "#FFFFFF",
    borderColor: "#FFFFFF",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    padding: "4px 12px",
    fontSize: "14px",
    fontWeight: "600",
    fontFamily: "Nunito, sans-serif",
    color: "gray",
  };

  const iconStyle = {
    color: "gray",
    opacity: 0.7,
    marginLeft: "2px",
    cursor: "pointer",
  };

  return (
    <div className="flex flex-col sm:flex-row items-center justify-between relative space-y-2 sm:space-y-0 sm:space-x-4">
      {/* Date selectors */}
      <div className="flex space-x-4 relative">
        {/* Start Date Picker */}
        <div style={datePickerContainerStyle}>
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            dateFormat="MM-dd-yyyy"
            className="bg-transparent border-none focus:ring-0 outline-none text-sm" // For remove the blue color default border
            popperClassName="z-50" // Ensure higher z-index for popup
            ref={startDatePickerRef} // Add ref to start date picker
          />
          <KeyboardArrowDownOutlinedIcon
            style={iconStyle}
            onClick={() => startDatePickerRef.current.setOpen(true)} // Open date picker on icon click
          />
        </div>

        {/* End Date Picker */}
        <div style={datePickerContainerStyle}>
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            dateFormat="MM-dd-yyyy"
            className="bg-transparent border-none focus:ring-0 outline-none text-sm" // For remove the blue color default border
            popperClassName="z-50" // Ensure higher z-index for popup
            ref={endDatePickerRef} // Add ref to end date picker
          />
          <KeyboardArrowDownOutlinedIcon
            style={iconStyle}
            onClick={() => endDatePickerRef.current.setOpen(true)} // Open date picker on icon click
          />
        </div>
      </div>
    </div>
  );
};

export default DateHeader;
