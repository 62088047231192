import React from "react";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

const ModalHeader = ({ title, onClose }) => {
  return (
    <div className="flex items-center mb-6 justify-between ml-6">
      <h3 className="text-fontColor text-lg sm:text-xl font-bold">{title}</h3>
      <button
        onClick={onClose}
        className="p-2 bg-red-50 text-red-500 rounded-full hover:bg-opacity-90 transition duration-150 flex"
      >
        <CloseRoundedIcon style={{ fontSize: "20px" }} />
      </button>
    </div>
  );
};

export default ModalHeader;
