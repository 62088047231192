import React, { useRef } from "react";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import PhoneInTalkOutlinedIcon from "@mui/icons-material/PhoneInTalkOutlined";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import ModalContainer from "../modal/ModalContainer";
import SaveButton from "../modal/SaveButton";

const ViewCustomer = ({ customer, onClose }) => {
  const modalRef = useRef();

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      onClose();
    }
  };

  const getInitials = (name) =>
    name
      .split(" ")
      .map((n) => n[0])
      .join("")
      .toUpperCase();

  const InfoRow = ({ icon: Icon, label }) => (
    <div className="flex items-start gap-3 text-fontColor opacity-70 mb-4">
      <Icon className="text-gray-500" style={{ fontSize: "18px" }} />
      <div className="flex-1 leading-tight text-justify">{label}</div>
    </div>
  );

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
      onClick={handleClickOutside}
    >
      <div
        ref={modalRef}
        className="bg-white rounded-lg p-8 w-full max-w-lg sm:w-2/3 md:w-1/2 lg:w-1/3 mx-4"
      >
        <ModalContainer>
          {/* Profile Picture and Name */}
          <div className="flex flex-col items-center mb-6">
            <div className="bg-gray-200 rounded-full w-24 h-24 flex items-center justify-center text-4xl text-fontColor opacity-70 font-bold mb-4">
              {getInitials(customer.customer_name)}
            </div>
            <h3 className="text-xl font-bold text-fontColor">
              {customer.customer_name}
            </h3>
            <p className="text-sm text-fontColor opacity-70 font-normal">
              {customer.customer_id}
            </p>
          </div>

          {/* Contact Information */}
          <div className="border-t border-gray-200 pt-4 flex flex-col mx-4 sm:mx-4">
            <h4 className="text-fontColor opacity-90 font-semibold mb-4">
              Customer Info
            </h4>
            <InfoRow
              icon={MailOutlineIcon}
              label={customer.email || "Not provided"}
            />
            <InfoRow
              icon={PhoneInTalkOutlinedIcon}
              label={customer.contact_number || "Not provided"}
            />
            <InfoRow
              icon={BusinessOutlinedIcon}
              label={`${customer.address || "N/A"}, ${customer.city || "N/A"}`}
            />
            <InfoRow
              icon={CalendarMonthRoundedIcon}
              label={customer.last_contacted || "No recent contact"}
            />
            <InfoRow
              icon={CategoryOutlinedIcon}
              label={customer.preferred_products || "No preferred Products"}
            />
            <SaveButton onClick={onClose} className="bg-red-500">
              <CloseRoundedIcon style={{ fontSize: "20px" }} /> Close
            </SaveButton>
          </div>
        </ModalContainer>
      </div>
    </div>
  );
};

export default ViewCustomer;
