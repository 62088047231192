import AddPhotoAlternateRoundedIcon from "@mui/icons-material/AddPhotoAlternateRounded";

const ImageField = ({
  label = "Product Image",
  imageSrc,
  onChange,
  error,
  inputClassName = "",
  iconClassName = "",
  wrapperClassName = "",
}) => {
  return (
    <div className={`mb-4 ${wrapperClassName}`}>
      <label className="block text-sm text-gray-600 mb-2">{label}</label>
      <div className="p-2 border border-gray-300 rounded w-full">
        <div className="flex flex-col sm:flex-row items-center space-y-2 sm:space-y-0 sm:space-x-4">
          {imageSrc ? (
            <img
              src={imageSrc}
              alt="Selected"
              className="w-16 h-16 object-cover rounded"
            />
          ) : (
            <AddPhotoAlternateRoundedIcon
              className={`text-gray-600 ${iconClassName}`}
              style={{ fontSize: "24px" }}
            />
          )}
          <input
            type="file"
            onChange={onChange}
            className={`text-sm text-gray-600 ${inputClassName}`}
          />
        </div>
        {error && <p className="text-red-500 text-xs mt-1">{error}</p>}
      </div>
    </div>
  );
};

export default ImageField;
