import React, { useState, useEffect } from "react";
import BusinessCenterRoundedIcon from "@mui/icons-material/BusinessCenterRounded";
import Field from "../modal/Field";
import ModalContainer from "../modal/ModalContainer";
import SaveButton from "../modal/SaveButton";

const AddNewSale = ({ onAdd }) => {
  const generateSaleId = () =>
    `S${Math.floor(100000 + Math.random() * 900000)}`;

  const [formData, setFormData] = useState({
    sale_id: generateSaleId(),
    customer_name: "",
    product_name: "",
    quantity: "",
    unit_price: "",
    sales_revenue: 0,
    sale_date: new Date().toISOString().split("T")[0],
  });

  const [customers, setCustomers] = useState([]);
  const [products, setProducts] = useState([]);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [customersRes, productsRes] = await Promise.all([
          fetch("/dummycustomers.json"),
          fetch("/dummyproductdata.json"),
        ]);

        if (!customersRes.ok || !productsRes.ok) {
          throw new Error("Failed to fetch data");
        }

        const customersData = await customersRes.json();
        const productsData = await productsRes.json();

        setCustomers(customersData);
        setProducts(productsData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setError(""); // Clear errors on input change

    if (name === "product_name") {
      const selectedProduct = products.find(
        (product) => product.product_name === value
      );
      if (selectedProduct) {
        setFormData((prev) => ({
          ...prev,
          unit_price: selectedProduct.unit_price || "",
        }));
      }
    }
  };

  const validateForm = () => {
    if (!formData.quantity || parseInt(formData.quantity) <= 0) {
      setError("Quantity must be a positive number.");
      return false;
    }

    if (!formData.customer_name || !formData.product_name) {
      setError("Please select a customer and a product.");
      return false;
    }

    if (!formData.unit_price || parseFloat(formData.unit_price) <= 0) {
      setError("Unit price must be valid.");
      return false;
    }

    setError("");
    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    formData.sales_revenue =
      parseFloat(formData.quantity) * parseFloat(formData.unit_price);
    onAdd(formData);

    setFormData({
      sale_id: generateSaleId(),
      customer_name: "",
      product_name: "",
      quantity: "",
      unit_price: "",
      sales_revenue: 0,
      sale_date: new Date().toISOString().split("T")[0],
    });
  };

  return (
    <ModalContainer>
      <form onSubmit={handleSubmit} className="flex flex-col mx-4 sm:mx-4">
        <Field
          label="Sale ID"
          type="text"
          name="sale_id"
          value={formData.sale_id}
          readOnly
          className="bg-gray-100 text-gray-600"
        />

        <Field
          label="Customer"
          type="select"
          name="customer_name"
          value={formData.customer_name}
          onChange={handleChange}
          required
          options={customers.map((customer) => customer.customer_name)}
        />

        <Field
          label="Product"
          type="select"
          name="product_name"
          value={formData.product_name}
          onChange={handleChange}
          required
          options={products.map((product) => product.product_name)}
        />

        <Field
          label="Quantity"
          type="number"
          name="quantity"
          value={formData.quantity}
          onChange={handleChange}
          placeholder="Quantity"
          required
          min="1"
        />

        <Field
          label="Unit Price"
          type="text"
          name="unit_price"
          value={formData.unit_price}
          readOnly
          placeholder="Unit Price"
          className="bg-gray-100 text-gray-600"
        />

        <Field
          label="Sale Date"
          type="date"
          name="sale_date"
          value={formData.sale_date}
          onChange={handleChange}
        />

        {error && <p className="text-red-500 text-sm mb-2">{error}</p>}

        <SaveButton
          type="submit"
          disabled={
            !formData.customer_name ||
            !formData.product_name ||
            !formData.quantity
          }
        >
          <BusinessCenterRoundedIcon
            style={{ fontSize: "20px", paddingBottom: "3px" }}
          />
          Add Sale
        </SaveButton>
      </form>
    </ModalContainer>
  );
};

export default AddNewSale;
