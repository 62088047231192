import React from "react";
import Field from "../modal/Field";

const ViewProduct = ({ product }) => {
  return (
    <div className="p-4 max-w-lg mx-auto">
      {/* Product Image */}
      <div className="flex justify-center mb-4">
        <img
          src={product.product_image || "https://via.placeholder.com/150"}
          alt={product.product_name}
          className="w-full h-64 object-cover rounded-lg shadow-md"
        />
      </div>

      {/* Product Details Form */}
      <form className="flex flex-col">
        <Field
          label="Product ID"
          name="product_id"
          value={product.product_id}
          readOnly={true}
          className="bg-gray-100 text-fontColor"
        />
        <Field
          label="Product Name"
          name="product_name"
          value={product.product_name}
          readOnly={true}
          className="bg-gray-100 text-fontColor"
        />
        <Field
          label="Product Category"
          name="product_category"
          value={product.product_category}
          readOnly={true}
          className="bg-gray-100 text-fontColor"
        />
        
        <Field
          label="Unit Price"
          name="unit_price"
          value={product.unit_price}
          readOnly={true}
          className="bg-gray-100 text-fontColor"
        />
        <Field
          label="Stock Quantity"
          name="stock_quantity"
          value={product.stock_quantity}
          readOnly={true}
          className="bg-gray-100 text-fontColor"
        />
        <Field
          label="Reorder Level"
          name="reorder_level"
          value={product.reorder_level}
          readOnly={true}
          className="bg-gray-100 text-fontColor"
        />
        <Field
          label="Product Description"
          type="textarea"
          name="description"
          value={product.description}
          readOnly={true}
          className="bg-gray-100 text-fontColor"
        />
      </form>
    </div>
  );
};

export default ViewProduct;
