import React from "react";

const SaveButton = ({
  type = "button",
  onClick,
  disabled = false,
  className = "",
  children,
}) => {
  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={`
        w-full bg-actionColor text-white py-2 rounded-[10px] hover:bg-opacity-90 
        transition duration-150 text-sm font-normal flex items-center justify-center gap-2 
        disabled:opacity-50 my-4  ${className}
      `}
    >
      {children}
    </button>
  );
};

export default SaveButton;
