import React from "react";

const ModalContainer = ({ children }) => {
  return (
    // Ensure the container height and scrolling are responsive
    <div className="max-h-96  overflow-y-auto overflow-x-hidden scroll-smooth w-full px-1">
      {children}
    </div>
  );
};

export default ModalContainer;
