import React, { useState, useEffect } from "react";
import SaveAsRoundedIcon from "@mui/icons-material/SaveAsRounded";
import ModalContainer from "../modal/ModalContainer";
import Field from "../modal/Field";
import SaveButton from "../modal/SaveButton";

const EditCustomer = ({ currentCustomer, onUpdate }) => {
  const today = new Date().toISOString().split("T")[0];

  const [formData, setFormData] = useState({
    ...currentCustomer,
    last_contacted: today, // Initialize last_contacted with today's date
  });

  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (currentCustomer) {
      setFormData({
        ...currentCustomer,
        last_contacted: today, // Always set last_contacted to today's date when loading
      });
    }
  }, [currentCustomer, today]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const validateForm = () => {
    const newErrors = {};

    // Validate customer name (only letters and spaces)
    if (!/^[a-zA-Z\s]+$/.test(formData.customer_name)) {
      newErrors.customer_name = "Customer name should contain only letters.";
    }

    // Validate contact number (10-digit phone number)
    if (
      !/^(?:\+94\s?|0)(?:\d{2}\s?\d{3}\s?\d{4})$/.test(formData.contact_number)
    ) {
      newErrors.contact_number =
        "Contact number should be a valid 10-digit phone number.";
    }

    // Validate email
    if (
      !/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(formData.email)
    ) {
      newErrors.email = "Please enter a valid email address.";
    }

    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate form
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    // Clear errors and proceed
    setErrors({});
    onUpdate(formData); // Pass the updated data back to the parent
  };

  return (
    <ModalContainer>
      <form onSubmit={handleSubmit} className="flex flex-col mx-4 sm:mx-4">
        <Field
          label="Customer ID"
          name="customer_id"
          value={formData.customer_id || ""}
          readOnly
          className="bg-gray-100 text-gray-600"
        />
        <Field
          label="Customer Name"
          name="customer_name"
          value={formData.customer_name || ""}
          onChange={handleChange}
          placeholder="Customer Name"
          required
        />
        {errors.customer_name && (
          <span className="text-red-500 text-sm">{errors.customer_name}</span>
        )}

        <Field
          label="Contact Number"
          name="contact_number"
          value={formData.contact_number || ""}
          onChange={handleChange}
          placeholder="Contact Number"
          required
        />
        {errors.contact_number && (
          <span className="text-red-500 text-sm">{errors.contact_number}</span>
        )}

        <Field
          label="Email"
          type="email"
          name="email"
          value={formData.email || ""}
          onChange={handleChange}
          placeholder="Email"
        />
        {errors.email && (
          <span className="text-red-500 text-sm">{errors.email}</span>
        )}

        <Field
          label="Address"
          type="text"
          name="address"
          value={formData.address || ""}
          onChange={handleChange}
          placeholder="Address"
        />

        <Field
          label="City"
          type="text"
          name="city"
          value={formData.city || ""}
          onChange={handleChange}
          placeholder="City"
          required
        />

        <Field
          label="Last Contacted"
          name="last_contacted"
          value={formData.last_contacted}
          onChange={handleChange}
          type="date"
        />

        <Field
          label="Preferred Products"
          name="preferred_products"
          value={formData.preferred_products || ""}
          onChange={handleChange}
          placeholder="Preferred Products"
          type="textarea"
        />

        <SaveButton
          type="submit"
          disabled={
            !formData.customer_name ||
            !formData.contact_number ||
            !formData.city
          }
        >
          <SaveAsRoundedIcon
            style={{ fontSize: "20px", paddingBottom: "3px" }}
          />
          Update Customer
        </SaveButton>
      </form>
    </ModalContainer>
  );
};

export default EditCustomer;
