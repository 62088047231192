import React from "react";
import { useLocation } from "react-router-dom";
import SidebarLink from "./sidebarlink";
import logo from "../../assets/sidebar_icons/Subtract.png";
import SpaceDashboardRoundedIcon from "@mui/icons-material/SpaceDashboardRounded";
import Inventory2RoundedIcon from "@mui/icons-material/Inventory2Rounded";
import BusinessCenterRoundedIcon from "@mui/icons-material/BusinessCenterRounded";
import TextSnippetRoundedIcon from "@mui/icons-material/TextSnippetRounded";
import WarehouseRoundedIcon from "@mui/icons-material/WarehouseRounded";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import upgradeIcon from "../../assets/sidebar_icons/upgrade.png";

const Sidebar = ({ toggleProfile, isCollapsed, setIsCollapsed }) => {
  const location = useLocation();
  const localStorageData = JSON.parse(localStorage.getItem("user"));

  const handleLogout = () => {
    localStorage.removeItem("user");
    window.location.href = "/login";
  };

  return (
    <div
      className={`h-screen ${
        isCollapsed ? "w-16" : "w-64"
      } bg-sidebarBackground shadow-lg flex flex-col justify-between transition-all duration-300`}
      onMouseEnter={() => setIsCollapsed(false)} // Expand on mouse enter
      onMouseLeave={() => setIsCollapsed(true)} // Collapse on mouse leave
    >
      {/* Sidebar Header */}
      <div className="p-3 mb-4 mt-2 flex items-center">
        <img src={logo} alt="Logo" className="w-8 h-8" />
        {!isCollapsed && <span className="text-2xl font-bold ml-3">Base</span>}
      </div>

      {/* Sidebar Links */}
      <div className="flex-1">
        <SidebarLink
          icon={SpaceDashboardRoundedIcon}
          label="Dashboard"
          path="/dashboard"
          isActive={location.pathname === "/dashboard"}
          isCollapsed={isCollapsed}
        />
        <SidebarLink
          icon={Inventory2RoundedIcon}
          label="Inventory"
          path="/inventory"
          isActive={location.pathname === "/inventory"}
          isCollapsed={isCollapsed}
        />
        <SidebarLink
          icon={TextSnippetRoundedIcon}
          label="Products"
          path="/products"
          isActive={location.pathname === "/products"}
          isCollapsed={isCollapsed}
        />
        <SidebarLink
          icon={BusinessCenterRoundedIcon}
          label="Sales"
          path="/sales"
          isActive={location.pathname === "/sales"}
          isCollapsed={isCollapsed}
        />
        <SidebarLink
          icon={WarehouseRoundedIcon}
          label="Stores & Customers"
          path="/stores"
          isActive={location.pathname === "/stores"}
          isCollapsed={isCollapsed}
        />
      </div>

      {/* Upgrade Section */}
      <div className="flex justify-center m-2 pb-3">
        <div
          className={`flex items-center ${
            isCollapsed ? "justify-center" : "justify-between"
          } w-auto max-w-xs`}
        >
          <img src={upgradeIcon} alt="Upgrade" className="w-10 h-10 m-2" />
          {!isCollapsed && (
            <button className="bg-actionColor text-white font-semibold text-[12px] py-2 px-8 rounded-[10px] hover:bg-opacity-90">
              Upgrade Now
            </button>
          )}
        </div>
      </div>

      {/* Profile Section with Logout Button */}
      <div className="sticky inset-x-0 bottom-0 border-t border-gray-300">
        <div
          className={`flex items-center ${
            isCollapsed ? "justify-center" : "justify-between"
          } bg-white p-4 w-full`}
        >
          <div
            className="flex items-start gap-2 cursor-pointer w-full"
            onClick={toggleProfile}
          >
            <div className="flex-shrink-0 h-8 w-8 rounded-full bg-gray-200 flex items-center justify-center overflow-hidden">
              {localStorageData.imageUrl ? (
                <img
                  alt="Profile"
                  src={localStorageData.imageUrl}
                  className="h-full w-full rounded-full object-cover"
                />
              ) : (
                <span className="text-sm font-bold text-fontColor">
                  {localStorageData.firstName?.[0]}
                  {localStorageData.lastName?.[0]}
                </span>
              )}
            </div>

            {!isCollapsed && (
              <div className="flex-grow">
                <p className="text-xs text-black truncate">
                  <strong className="block font-semibold">
                    {localStorageData.firstName +
                      " " +
                      localStorageData.lastName}
                  </strong>
                  <span className="text-[10px] text-gray-400 opacity-70">
                    {localStorageData.email}
                  </span>
                </p>
              </div>
            )}
          </div>

          {!isCollapsed && (
            <div className="flex-shrink-0 ml-2">
              <LogoutRoundedIcon
                onClick={handleLogout}
                className="cursor-pointer text-fontColor opacity-60 hover:text-red-600"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
