import React from "react";

const DeleteConfirmation = ({ onConfirm, onCancel }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-white rounded-[10px] p-4 w-full max-w-sm mx-4">
        <h3 className="text-lg text-fontColor font-semibold mb-4">
          Are you sure you want to delete?
        </h3>
        <div className="flex justify-end gap-3">
          <button
            onClick={onCancel}
            className="px-4 py-2 bg-gray-300 text-fontColor text-base rounded-lg hover:bg-opacity-70 transition-colors"
          >
            No
          </button>
          <button
            onClick={onConfirm}
            className="px-4 py-2 bg-red-500 text-white text-base rounded-lg hover:bg-opacity-70 transition-colors"
          >
            Yes
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteConfirmation;
