import React, { useState } from "react";
import { Link } from "react-router-dom";
import MoreHorizRoundedIcon from "@mui/icons-material/MoreHorizRounded";
import Popover from "@mui/material/Popover";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";

const ViewAllLink = ({ menuItems }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <MoreHorizRoundedIcon
        onClick={handleClick}
        className="cursor-pointer text-black opacity-40"
      />

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <MenuList
          sx={{
            borderRadius: "10px",
            backgroundColor: "#fff",
            padding: "10px 0",
          }}
        >
          {menuItems.map((item, index) => (
            <MenuItem
              key={index}
              component={item.to ? Link : "button"}
              to={item.to}
              onClick={handleClose}
              sx={{
                fontFamily: "Nunito, sans-serif",
                fontSize: "12px",
                color: "#030229",
                "&:hover": {
                  backgroundColor: "#F0F0F0",
                },
              }}
            >
              {item.label}
            </MenuItem>
          ))}
        </MenuList>
      </Popover>
    </div>
  );
};

export default ViewAllLink;
