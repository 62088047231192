import React, { useState, useEffect } from "react";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import NoResultsMessage from "../components/NoResultsMessage";

const Inventory = () => {
  const [inventoryLogs, setInventoryLogs] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const loadInventoryData = async () => {
      try {
        // Fetch product and sales data
        const [productsRes, salesRes] = await Promise.all([
          fetch("/dummyproductdata.json"),
          fetch("/dummysales.json"),
        ]);

        if (!productsRes.ok || !salesRes.ok) {
          throw new Error("Network response was not ok");
        }

        const products = await productsRes.json();
        const sales = await salesRes.json();

        // Calculate current stock and statuses
        const updatedInventory = products.map((product) => {
          const totalSales = sales
            .filter((sale) => sale.product_name === product.product_name)
            .reduce((sum, sale) => sum + sale.quantity, 0);

          const currentStock = Math.max(product.stock_quantity - totalSales, 0);

          let status = "Available";
          if (currentStock === 0) status = "Unavailable";
          else if (currentStock <= product.reorder_level)
            status = "Reorder Level";

          return {
            tracking_id: product.product_id,
            product_name: product.product_name,
            current_stock: currentStock,
            status,
            last_recorded_date: new Date().toISOString().split("T")[0], // Current date
          };
        });

        setInventoryLogs(updatedInventory);
      } catch (error) {
        console.error("Error fetching inventory data:", error);
      }
    };

    loadInventoryData();
  }, []);

  const filteredLogs = inventoryLogs.filter((log) =>
    log.product_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="h-full flex flex-col gap-6">
      <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between mb-6 gap-6">
        <h1 className="text-2xl font-bold text-fontColor">
          Inventory Management
        </h1>
        <div className="flex flex-col sm:flex-row sm:items-center gap-4 w-full sm:w-auto">
          <div className="relative flex-1 sm:max-w-xs">
            <SearchRoundedIcon
              className="absolute left-3 top-1/2 transform -translate-y-1/2 text-fontColor opacity-70"
              style={{ fontSize: "1.5rem" }}
            />
            <input
              type="text"
              placeholder="Search by product name..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full pl-10 pr-3 py-2 border border-gray-200 rounded-lg focus:outline-none focus:border-blue-400"
            />
          </div>
        </div>
      </div>

      <table className="min-w-full bg-componentBackground rounded-lg shadow-md">
        <thead>
          <tr>
            <th className="border-b border-gray-200 p-4 text-fontColor opacity-70 text-base font-semibold text-left">
              Product ID
            </th>
            <th className="border-b border-gray-200 p-4 text-fontColor opacity-70 text-base font-semibold text-left">
              Product Name
            </th>
            <th className="border-b border-gray-200 p-4 text-fontColor opacity-70 text-base font-semibold text-left">
              Current Stock
            </th>
            <th className="border-b border-gray-200 p-4 text-fontColor opacity-70 text-base font-semibold text-left">
              Status
            </th>
            <th className="border-b border-gray-200 p-4 text-fontColor opacity-70 text-base font-semibold text-left">
              Last Recorded Date
            </th>
          </tr>
        </thead>
        <tbody>
          {filteredLogs.length > 0 ? (
            filteredLogs.map((log) => (
              <tr
                key={log.tracking_id}
                className="hover:bg-gray-50 border-b border-gray-200"
              >
                <td className="p-4 text-sm text-fontColor font-normal text-left">
                  {log.tracking_id}
                </td>
                <td className="p-4 text-sm text-fontColor font-normal text-left">
                  {log.product_name}
                </td>
                <td className="p-4 text-sm text-fontColor font-normal text-left">
                  {log.current_stock}
                </td>
                <td className="p-4 text-sm text-fontColor font-normal text-left">
                  <span
                    className={`badge ${
                      log.status === "Available"
                        ? "bg-green-100 text-green-500"
                        : log.status === "Reorder Level"
                        ? "bg-yellow-100 text-yellow-500"
                        : "bg-red-100 text-red-500"
                    } px-2 py-1 rounded-xl`}
                  >
                    {log.status}
                  </span>
                </td>

                <td className="p-4 text-sm text-fontColor font-normal text-left">
                  {log.last_recorded_date}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5" className="text-center py-4">
                <NoResultsMessage message="No results found for the search criteria." />
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Inventory;
