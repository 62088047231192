import React, { useState } from "react";
import ViewProfile from "./ViewProfile";
import EditProfile from "./EditProfile";

const ProfileDetails = ({ isVisible, onClose }) => {
  const [isEditMode, setIsEditMode] = useState(false);

  const toggleEditMode = () => {
    setIsEditMode(!isEditMode);
  };

  return (
    <>
      {/* Overlay Background */}
      {isVisible && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 transition-opacity duration-300"
          onClick={onClose} // Close on overlay click
        ></div>
      )}

      {/* Profile Sidebar Panel */}
      <div
        className={`fixed top-0 right-0 h-screen w-full md:w-1/4 bg-componentBackground shadow-inner transform transition-transform duration-300 ${
          isVisible ? "translate-x-0" : "translate-x-full"
        }`}
      >
        {isEditMode ? (
          <EditProfile onClose={onClose} toggleEditMode={toggleEditMode} />
        ) : (
          <ViewProfile toggleEditMode={toggleEditMode} onClose={onClose} />
        )}
      </div>
    </>
  );
};

export default ProfileDetails;
