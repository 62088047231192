import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import SortIcon from "@mui/icons-material/Sort";

/**
 * Table component to display sortable data.
 * @param {Object} props - Contains columns, data, requestSort function, and current sort configuration.
 * @returns {JSX.Element} - Rendered table component.
 */
const Table = ({ columns, data, requestSort, sortConfig }) => {
  // Function to get the appropriate sort icon based on current sort configuration
  const getSortIcon = (key) => {
    if (sortConfig && sortConfig.key === key) {
      return sortConfig.direction === "ascending" ? (
        <ExpandLessIcon
          fontSize="13px"
          style={{ marginLeft: "10px", opacity: 0.5 }}
        />
      ) : (
        <ExpandMoreIcon
          fontSize="13px"
          style={{ marginLeft: "10px", opacity: 0.5 }}
        />
      );
    }
    return (
      <SortIcon fontSize="13px" style={{ marginLeft: "10px", opacity: 0.5 }} />
    );
  };

  return (
    <table className="min-w-full table-auto">
      <thead>
        <tr>
          {/* Render each column header with sorting functionality */}
          {columns.map((column) => (
            <th
              key={column.key}
              className={`px-4 py-4 text-left text-fontColor font-medium text-sm cursor-pointer ${column.align}`}
              onClick={() => requestSort(column.key, column.isNumeric)} // Request sort on header click
            >
              <div className="flex items-center">
                {column.label} {getSortIcon(column.key)}{" "}
                {/* Display sort icon */}
              </div>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {/* Render each row of data */}
        {data.map((row, index) => (
          <tr key={index} className="border-t">
            {/* Render each cell in the row */}
            {columns.map((column) => (
              <td
                key={column.key}
                className={`px-4 py-4 text-fontColor text-sm ${column.align}`}
              >
                {/* Render cell content, allowing for custom rendering */}
                {column.render
                  ? column.render(row[column.key], row)
                  : row[column.key]}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Table;
