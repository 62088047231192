import React, { useState, useEffect } from "react";
import AddNewProduct from "../components/products/AddNewProduct";
import EditProduct from "../components/products/EditProduct";
import ViewProduct from "../components/products/ViewProduct";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import NoResultsMessage from "../components/NoResultsMessage";
import ModalContainer from "../components/modal/ModalContainer";
import ModalHeader from "../components/modal/ModalHeader";
import DeleteConfirmation from "../components/modal/DeleteConfirmation";

const Products = () => {
  const [products, setProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);
  const [productToEdit, setProductToEdit] = useState(null);
  const [productToView, setProductToView] = useState(null);
  const [productToDeleteId, setProductToDeleteId] = useState(null);

  // Load dummy data
  useEffect(() => {
    const loadProducts = async () => {
      try {
        const response = await fetch("/dummyproductdata.json");
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setProducts(data);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    loadProducts();
  }, []);

  const handleAddProduct = (newProduct) => {
    const updatedProducts = [...products, newProduct];
    setProducts(updatedProducts);
    setIsAddModalOpen(false);
  };

  const handleDeleteProduct = (id) => {
    setProductToDeleteId(id);
    setIsDeleteConfirmationOpen(true);
  };

  const confirmDelete = () => {
    const updatedProducts = products.filter(
      (product) => product.product_id !== productToDeleteId
    );
    setProducts(updatedProducts);
    setIsDeleteConfirmationOpen(false);
  };

  const cancelDelete = () => {
    setIsDeleteConfirmationOpen(false);
    setProductToDeleteId(null);
  };

  const handleEditProduct = (id) => {
    const productToEdit = products.find((product) => product.product_id === id);
    if (productToEdit) {
      setProductToEdit(productToEdit);
      setIsEditModalOpen(true);
    }
  };

  const handleUpdateProduct = (updatedProduct) => {
    const updatedProducts = products.map((product) =>
      product.product_id === updatedProduct.product_id
        ? updatedProduct
        : product
    );
    setProducts(updatedProducts);
    setIsEditModalOpen(false);
  };

  const handleViewProduct = (id) => {
    const productToView = products.find((product) => product.product_id === id);
    if (productToView) {
      setProductToView(productToView);
      setIsViewModalOpen(true);
    }
  };

  const toggleAddModal = () => {
    setIsAddModalOpen(!isAddModalOpen);
  };

  const toggleEditModal = () => {
    setIsEditModalOpen(!isEditModalOpen);
  };

  const toggleViewModal = () => {
    setIsViewModalOpen(!isViewModalOpen);
  };

  return (
    <div className="h-full flex flex-col gap-6">
      <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between mb-6 gap-6">
        <h1 className="text-2xl font-bold text-fontColor">Product List</h1>

        {/* Search input and Add New button container */}
        <div className="flex flex-col sm:flex-row sm:items-center gap-4 w-full sm:w-auto">
          {/* Search input */}
          <div className="relative flex-1 sm:max-w-xs">
            <SearchRoundedIcon
              className="absolute left-3 top-1/2 transform -translate-y-1/2 text-fontColor opacity-70"
              style={{ fontSize: "1.5rem" }}
            />
            <input
              type="text"
              placeholder="Search Products..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full pl-10 pr-3 py-2 border border-gray-200 rounded-lg focus:outline-none focus:border-blue-400"
            />
          </div>

          {/* Add New button */}
          <button
            onClick={toggleAddModal}
            className="px-5 py-2 bg-actionColor text-white text-base rounded-lg flex items-center justify-center hover:bg-opacity-90 transition-colors"
          >
            <AddRoundedIcon className="mr-2" style={{ fontSize: "18px" }} />
            Add New Product
          </button>
        </div>
      </div>

      <table className="min-w-full bg-componentBackground rounded-lg shadow-md">
        <thead>
          <tr>
            <th className="border-b border-gray-200 p-4 text-fontColor opacity-70 text-base font-semibold text-left">
              Product ID
            </th>
            <th className="border-b border-gray-200 p-4 text-fontColor opacity-70 text-base font-semibold text-left">
              Product Name
            </th>
            <th className="border-b border-gray-200 p-4 text-fontColor opacity-70 text-base font-semibold text-left">
              Unit Price
            </th>
            <th className="border-b border-gray-200 p-4 text-fontColor opacity-70 text-base font-semibold text-left">
              Stock Quantity
            </th>
            <th className="border-b border-gray-200 p-4 text-fontColor opacity-70 text-base font-semibold text-left">
              Total Income
            </th>
            <th className="border-b border-gray-200 p-4 text-fontColor opacity-70 text-base font-semibold text-left">
              Date Added
            </th>
            <th className="border-b border-gray-200 p-4 text-fontColor opacity-70 text-base font-semibold text-left">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {products.filter((product) =>
            product.product_name
              .toLowerCase()
              .includes(searchTerm.toLowerCase())
          ).length > 0 ? (
            products
              .filter((product) =>
                product.product_name
                  .toLowerCase()
                  .includes(searchTerm.toLowerCase())
              )
              .map((product) => (
                <tr
                  key={product.product_id}
                  className="hover:bg-gray-50 border-b border-gray-200 cursor-pointer"
                  onClick={() => handleViewProduct(product.product_id)} // Added click event
                >
                  <td className="p-4 text-sm text-fontColor font-normal text-left">
                    {product.product_id}
                  </td>
                  <td className="p-4 text-sm text-fontColor font-normal text-left">
                    <div className="flex items-center gap-2">
                      <img
                        src={
                          product.product_image ||
                          "https://via.placeholder.com/50"
                        }
                        alt="Product"
                        className="w-8 h-8 mr-2 rounded"
                      />
                      {product.product_name}
                    </div>
                  </td>
                  <td className="p-4 text-sm text-fontColor font-normal text-left">
                    {!isNaN(parseFloat(product.unit_price))
                      ? parseFloat(product.unit_price).toFixed(2)
                      : "N/A"}
                  </td>
                  <td className="p-4 ml-10 mb-2 text-sm font-medium bg-[#26C0E21A] rounded-md inline-block px-1 py-1 text-center w-12 text-[#26C0E2]">
                    {product.stock_quantity}
                  </td>
                  <td className="p-4 text-sm text-fontColor font-normal text-left">
                    {(product.unit_price * product.stock_quantity).toFixed(2)}
                  </td>
                  <td className="p-4 text-sm text-fontColor font-normal text-left">
                    {product.date_added}
                  </td>
                  <td className="p-4 flex space-x-2">
                    <div
                      className="bg-blue-50 rounded-full cursor-pointer flex items-center justify-center"
                      style={{ width: "32px", height: "32px" }}
                    >
                      <EditIcon
                        onClick={(e) => {
                          e.stopPropagation(); // Prevents the click event from propagating to the row's onClick
                          handleEditProduct(product.product_id);
                        }}
                        className="text-blue-500"
                        style={{ fontSize: "18px" }}
                      />
                    </div>
                    <div
                      className="bg-red-50 rounded-full cursor-pointer flex items-center justify-center"
                      style={{ width: "32px", height: "32px" }}
                    >
                      <DeleteIcon
                        onClick={(e) => {
                          e.stopPropagation(); // Prevents the click event from propagating to the row's onClick
                          handleDeleteProduct(product.product_id);
                        }}
                        className="text-red-500"
                        style={{ fontSize: "18px" }}
                      />
                    </div>
                  </td>
                </tr>
              ))
          ) : (
            <tr>
              <td colSpan="10" className="text-center py-4">
                <NoResultsMessage message="No Results Found" />
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {/* Modal for Add New Product */}
      {isAddModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white rounded-lg p-4 w-full max-w-lg sm:w-2/3 md:w-1/2 lg:w-1/3 mx-4">
            {/* Modal header */}
            <ModalHeader title="Add New Product" onClose={toggleAddModal} />
            <ModalContainer>
              <AddNewProduct onAdd={handleAddProduct} />
            </ModalContainer>
          </div>
        </div>
      )}

      {/* Modal for Edit Product */}
      {isEditModalOpen && productToEdit && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white rounded-lg p-4 w-full max-w-lg sm:w-2/3 md:w-1/2 lg:w-1/3 mx-4">
            {/* Modal Header */}
            <ModalHeader title="Edit Product" onClose={toggleEditModal} />
            <ModalContainer>
              <EditProduct
                product={productToEdit}
                onUpdate={handleUpdateProduct}
                onClose={toggleEditModal}
              />
            </ModalContainer>
          </div>
        </div>
      )}

      {/* Modal for View Product */}
      {isViewModalOpen && productToView && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white rounded-lg p-4 w-full max-w-lg sm:w-2/3 md:w-1/2 lg:w-1/3 mx-4">
            {/* Modal Header */}
            <ModalHeader title="Product Detail" onClose={toggleViewModal} />
            <ModalContainer>
              <ViewProduct product={productToView} onClose={toggleViewModal} />
            </ModalContainer>
          </div>
        </div>
      )}

      {isDeleteConfirmationOpen && (
        <DeleteConfirmation onConfirm={confirmDelete} onCancel={cancelDelete} />
      )}
    </div>
  );
};

export default Products;
