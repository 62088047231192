const ENV = () => {
    var API_ENV = "";
    if (window.location.href.includes("localhost") || window.location.href.includes("127.0.0.1")){
        API_ENV = "http://localhost:4000"
    }else if (window.location.href.includes("https://inv.codecraftive.com")){
        API_ENV = "https://invapi.codecraftive.com"
    }else if (window.location.href.includes("https://dev.inv.codecraftive.com")){
        API_ENV = "https://dev.invapi.codecraftive.com"
    }else if (window.location.href.includes("https://qa.inv.codecraftive.com")){
        API_ENV = "https://qa.invapi.codecraftive.com"
    }else if (window.location.href.includes("https://uat.inv.codecraftive.com")){
        API_ENV = "https://uat.invapi.codecraftive.com"
    }else{
        API_ENV = "http://localhost:4000"
    }

    return API_ENV;
}

export default ENV;