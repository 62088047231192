import React, { useState } from "react";
import CameraAltRoundedIcon from "@mui/icons-material/CameraAltRounded";
import UploadImage from "../UploadImage";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import CountryFlags from "./CountryFlags"; //CountryFlags component

const EditProfile = ({ onClose, toggleEditMode }) => {
  const userData = JSON.parse(localStorage.getItem("user")) || {};
  const [formData, setFormData] = useState({
    firstName: userData.firstName || "",
    lastName: userData.lastName || "",
    phoneNumber: userData.phoneNumber || "",
    imageUrl: userData.imageUrl || "",
    countryCode: userData.countryCode || "+94", // Default to Sri Lanka (+94)
  });

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const saveChanges = () => {
    fetch("/api/updateProfile", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        localStorage.setItem("user", JSON.stringify(data));
        toggleEditMode(); // Close the edit mode
      })
      .catch((error) => console.error("Error updating profile:", error));
  };

  const handleImageUpload = (imageUrl) => {
    setFormData({ ...formData, imageUrl });
  };

  return (
    <div className="p-8 h-full mx-4 bg-componentBackground space-y-6">
      <div className="flex items-center justify-between relative">
        <h2 className="absolute left-1/2 transform -translate-x-1/2 text-xl font-semibold text-fontColor">
          Edit Profile Info
        </h2>
        <button
          onClick={toggleEditMode}
          className="p-1 bg-slate-100 rounded-full hover:bg-slate-200 flex items-center justify-center"
          style={{ width: "36px", height: "36px" }}
        >
          <ArrowBackIosNewRoundedIcon
            className="text-gray-400"
            fontSize="small"
          />
        </button>
      </div>

      {/* Profile Image / Initials */}
      <div className="text-center relative">
        <div className="w-24 h-24 rounded-full bg-gray-200 flex items-center justify-center mx-auto relative">
          {formData.imageUrl ? (
            <img
              src={formData.imageUrl}
              alt="Profile"
              className="w-full h-full object-cover rounded-full"
            />
          ) : (
            <span className="text-3xl font-bold text-fontColor">
              {formData.firstName?.[0]}
              {formData.lastName?.[0]}
            </span>
          )}
          <label
            htmlFor="upload-input"
            className="absolute bottom-0 right-0 bg-actionColor text-white text-sm p-2 rounded-full cursor-pointer"
          >
            <CameraAltRoundedIcon style={{ fontSize: "1.3rem" }} />
          </label>
          <UploadImage onUpload={handleImageUpload} />
        </div>
      </div>

      {/* User Information */}
      <div className="pt-6 space-y-6">
        <div>
          <label className="block text-fontColor font-medium mb-2 text-base">
            First Name
          </label>
          <input
            type="text"
            name="firstName"
            value={formData.firstName}
            onChange={handleInputChange}
            className="w-full bg-slate-50 border border-gray-400 rounded-md p-2 text-fontColor opacity-90 font-normal text-base"
          />
        </div>
        <div>
          <label className="block text-fontColor font-medium mb-2 text-base">
            Last Name
          </label>
          <input
            type="text"
            name="lastName"
            value={formData.lastName}
            onChange={handleInputChange}
            className="w-full bg-slate-50 border border-gray-400 rounded-md p-2 text-fontColor opacity-90 font-normal text-base"
          />
        </div>
        <div>
          <label className="block text-fontColor font-medium mb-2 text-base">
            Phone Number
          </label>
          <div className="flex items-center space-x-3">
            {/* Country Flag Component */}
            <div className="flex items-center space-x-2 text-xs">
              <CountryFlags countryCode={formData.countryCode} />
              <select
                name="countryCode"
                value={formData.countryCode}
                onChange={handleInputChange}
                className="bg-slate-50 border border-gray-400 rounded-md p-3 text-fontColor opacity-90 font-normal text-xs w-20"
              >
                <option value="+94">+94</option>
                <option value="+1">+1</option>
                <option value="+44">+44</option>
                <option value="+91">+91</option>
                {/* Add more countries here */}
              </select>
            </div>
            <input
              type="text"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleInputChange}
              className="w-full bg-slate-50 border border-gray-400 rounded-md p-2 text-fontColor opacity-90 font-normal text-base"
              maxLength={10} // Ensure only 10 numbers can be entered
              placeholder="1234567890"
            />
          </div>
        </div>
      </div>

      {/* Save Button */}
      <div className="pt-6 text-center">
        <button
          className="w-full bg-actionColor text-white py-2 rounded-[10px] hover:bg-opacity-90 transition duration-150 text-base font-normal flex items-center justify-center gap-2"
          onClick={saveChanges}
        >
          <SaveRoundedIcon style={{ fontSize: "16px" }} />
          Save
        </button>
      </div>
    </div>
  );
};

export default EditProfile;
