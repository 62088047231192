import React from "react";
import RecentOrders from "../components/orders/RecentOrders";
import TopSellingProducts from "../components/products/TopSellingProducts";
import LineGraph from "../components/graphs/LineGraph";
import DonutGraph from "../components/graphs/DonutGraph";
import Cards from "../components/cards/Cardgrid";
import DateHeader from "../components/header/datebar";

const Dashboard = () => {
  return (
    <div className="h-full flex flex-col gap-6">
      {/* Flexbox to align h1 and DateHeader in the same row */}
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold text-fontColor">Dashboard</h1>
        <DateHeader />
      </div>

      {/* make responsive using this div later */}
      <div>
        <Cards />
      </div>

      {/* Adjusted grid classes for responsive layout */}
      <div className="grid grid-cols-1 md:grid-cols-8 gap-4">
        <div className="md:col-span-5">
          <LineGraph />
        </div>
        <div className="md:col-span-3">
          <DonutGraph />
        </div>
      </div>

      {/* Adjusted flex for responsiveness */}
      <div className="grid grid-cols-1 md:grid-cols-8 gap-4">
        <div className="md:col-span-5 mb-5">
          <RecentOrders />
        </div>
        <div className="md:col-span-3 mb-5">
          <TopSellingProducts />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
