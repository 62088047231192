import React from "react";

const NoResultsMessage = ({ message }) => {
  return (
    <tr>
      <td colSpan="8" className="text-center p-4 text-gray-500">
        {message}
      </td>
    </tr>
  );
};

export default NoResultsMessage;
