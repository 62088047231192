import React, { useState } from "react";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import ModalContainer from "../modal/ModalContainer";
import Field from "../modal/Field";
import SaveButton from "../modal/SaveButton";
import ImageField from "../modal/ImageField";

const AddNewProduct = ({ onAdd }) => {
  const [product, setProduct] = useState({
    product_id: Date.now(),
    product_name: "",
    product_category: "",
    description: "",
    unit_price: "",
    stock_quantity: "",
    reorder_level: "",
    date_added: new Date().toLocaleDateString(),
    product_image: null,
  });

  const [error, setError] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProduct((prev) => ({ ...prev, [name]: value }));
    setError("");
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) {
        setError("File size must be less than 5MB.");
        return;
      }
      if (!["image/jpeg", "image/png"].includes(file.type)) {
        setError("Only JPEG and PNG image formats are supported.");
        return;
      }
      const reader = new FileReader();
      reader.onload = () => {
        setProduct((prev) => ({ ...prev, product_image: reader.result }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      !product.product_name ||
      !product.unit_price ||
      !product.stock_quantity
    ) {
      setError("Please fill in all required fields.");
      return;
    }
    onAdd(product);
    setProduct({
      product_id: Date.now(),
      product_name: "",
      product_category: "",
      description: "",
      unit_price: "",
      stock_quantity: "",
      reorder_level: "",
      date_added: new Date().toLocaleDateString(),
      product_image: null,
    });
    setError("");
  };

  const categories = [
    "Electronics",
    "Clothing",
    "Groceries",
    "Books",
    "Home Appliances",
  ];

  return (
    <ModalContainer>
      <form onSubmit={handleSubmit} className="flex flex-col mx-4 sm:mx-4">
        <Field
          label="Product ID"
          type="text"
          name="product_id"
          value={product.product_id}
          readOnly
          className="bg-gray-100 text-gray-600"
        />

        <Field
          label="Product Name"
          name="product_name"
          value={product.product_name}
          onChange={handleChange}
          placeholder="Enter product name"
          required
        />

        <Field
          label="Product Category"
          type="select"
          name="product_category"
          value={product.product_category}
          onChange={handleChange}
          placeholder="Select a Category"
          options={categories}
          required
        />

        <Field
          label="Description"
          type="textarea"
          name="description"
          value={product.description}
          onChange={handleChange}
          placeholder="Enter product description"
        />

        <Field
          label="Unit Price"
          type="number"
          name="unit_price"
          value={product.unit_price}
          onChange={handleChange}
          placeholder="RS.199.99"
          min="0"
          step="0.01"
          required
        />

        <Field
          label="Stock Quantity"
          type="number"
          name="stock_quantity"
          value={product.stock_quantity}
          onChange={handleChange}
          placeholder="0"
          min="0"
          required
        />

        <Field
          label="Reorder Level"
          type="number"
          name="reorder_level"
          value={product.reorder_level}
          onChange={handleChange}
          placeholder="0"
          min="0"
        />

        <ImageField
          imageSrc={product.product_image}
          onChange={handleImageChange}
          error={error}
        />

        <SaveButton
          type="submit"
          disabled={
            !product.product_name ||
            !product.product_category ||
            !product.unit_price ||
            !product.stock_quantity
          }
        >
          <AddCircleRoundedIcon style={{ fontSize: "20px" }} /> Add Product
        </SaveButton>
      </form>
    </ModalContainer>
  );
};

export default AddNewProduct;
