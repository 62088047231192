import { useState } from "react";

/**
 * Custom hook to handle sorting of data.
 * @param {Array} items - The initial array of items to be sorted.
 * @param {Object} config - Initial sort configuration (optional).
 * @returns {Object} - Contains sorted items, a requestSort function, and current sort configuration.
 */
export const useSortableData = (items, config = null) => {
  const [sortConfig, setSortConfig] = useState(config); // State to hold sort configuration

  // Sorting the items based on the current sort configuration
  const sortedItems = [...items].sort((a, b) => {
    if (sortConfig !== null) {
      const { key, direction, isNumeric } = sortConfig;

      // Get the value to be compared based on whether it's numeric or not
      const aValue = isNumeric
        ? parseFloat(a[key].replace(/[^0-9.-]+/g, "")) // Convert to number if numeric
        : a[key];
      const bValue = isNumeric
        ? parseFloat(b[key].replace(/[^0-9.-]+/g, ""))
        : b[key];

      // Compare values based on the sorting direction
      if (direction === "ascending") {
        return aValue > bValue ? 1 : -1;
      } else {
        return aValue < bValue ? 1 : -1;
      }
    }
    return 0; // Return 0 if no sorting is defined
  });

  // Function to request a sort on a specific key
  const requestSort = (key, isNumeric = false) => {
    let direction = "ascending";
    // Toggle direction if the same key is clicked
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    // Update the sort configuration state
    setSortConfig({ key, direction, isNumeric });
  };

  return { sortedItems, requestSort, sortConfig }; // Return sorted items and functions
};
