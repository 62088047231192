import React from "react";

const Field = ({
  label,
  type = "text",
  name,
  value,
  onChange,
  placeholder,
  readOnly = false,
  required = false,
  className = "",
  options = [], // Array for dropdown options
  min,
  step,
}) => {
  return (
    <div className="mb-4">
      <label className="block text-sm text-gray-600 mb-2">{label}</label>
      {type === "textarea" ? (
        <textarea
          name={name}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          required={required}
          readOnly={readOnly}
          className={`border border-gray-300 rounded p-2 w-full h-40 text-sm ${className}`}
        />
      ) : type === "select" ? (
        <select
          name={name}
          value={value}
          onChange={onChange}
          required={required}
          className={`border border-gray-300 rounded p-2 w-full text-sm ${className}`}
        >
          <option value="" disabled>
            {placeholder || "Select an option"}
          </option>
          {options.map((option, index) => (
            <option key={index} value={option}>
              {option}
            </option>
          ))}
        </select>
      ) : (
        <input
          type={type}
          name={name}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          required={required}
          readOnly={readOnly}
          className={`border border-gray-300 rounded p-2 w-full text-sm ${className}`}
          min={min}
          step={step}
        />
      )}
    </div>
  );
};

export default Field;
