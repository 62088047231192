import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "./sidebar/sidebar";
import ProfileDetails from "./profile/ProfileDetails";

function Layout() {
  const [isProfileVisible, setIsProfileVisible] = useState(false);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(true); // Start collapsed

  const toggleProfile = () => {
    setIsProfileVisible(!isProfileVisible);
  };

  return (
    <div
      className="relative h-screen bg-pageBackground grid transition-all duration-300"
      style={{
        gridTemplateColumns: isSidebarCollapsed ? "80px 1fr" : "280px 1fr",
      }}
    >
      {/* Sidebar */}
      <div className="h-full bg-transparent hidden lg:flex">
        <Sidebar
          toggleProfile={toggleProfile}
          isCollapsed={isSidebarCollapsed}
          setIsCollapsed={setIsSidebarCollapsed} // Pass set function
        />
      </div>

      {/* Main content area */}
      <div className="pt-4 pr-6 overflow-auto">
        <Outlet />
      </div>

      {/* Profile Details Slide-In Panel */}
      <ProfileDetails isVisible={isProfileVisible} onClose={toggleProfile} />
    </div>
  );
}

export default Layout;
