import React from "react";

// Image
import NIKE from "../../assets/products/TopSell.png";
import ViewAllLink from "../menu/ViewAllLink";

const topSellingProductsData = [
  {
    productName: "NIKE Shoes Black Pattern",
    rating: 5,
    price: "$87",
    productImage: NIKE, //image
  },
  {
    productName: "iPhone 12",
    rating: 5,
    price: "$987",
    productImage: NIKE,
  },
];

const TopSellingProducts = () => {
  return (
    <div className="w-full h-full bg-componentBackground shadow-sm rounded-[10px] p-4 overflow-auto">
      <div className="flex justify-between items-center mb-8">
        <h2 className="text-lg font-bold text-black opacity-70">
          Top Selling Products
        </h2>
        {/*usethis part access to the ViewAllLink component, i can change menu item and target page here */}
        <ViewAllLink
          menuItems={[
            { label: "View All", to: "/sales" },
            { label: "Option 2" },
          ]}
        />
      </div>
      <div className="grid grid-cols-1 gap-4">
        {topSellingProductsData.map((product, index) => (
          <div
            key={index}
            className="flex items-center border-b pb-6 mb-2 last:border-b-0" // line
          >
            <img
              src={product.productImage}
              alt={product.productName}
              className="w-20 h-20 rounded-lg object-cover mr-4"
            />
            <div>
              <h3 className="text-[15px] font-normal text-black">
                {product.productName}
              </h3>
              <div className="flex items-center">
                <span className="text-yellow-500 text-sm">
                  {"★".repeat(product.rating)}
                </span>
                {/* <span className="ml-2 text-gray-500 text-sm">
                  {product.rating} Stars
                </span> */}
              </div>
              <p className="text-[15px] font-bold mt-1 text-black">
                {product.price}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TopSellingProducts;
