import React from "react";
import Card from "../cards/Card";

//Icon Set
import saveIcon from "../../assets/cards_icon/Heart.png";
import stockIcon from "../../assets/cards_icon/Game.png";
import salesIcon from "../../assets/cards_icon/Bag.png";
import jobIcon from "../../assets/cards_icon/Work.png";

// Example data
const cardData = [
  {
    icon: saveIcon,
    value: "178+",
    label: "Sales",
    color: "#E6F7FE",
    shadow: "shadow-gray-200",
    percentage: 67.81,
  },
  {
    icon: stockIcon,
    value: "20+",
    label: "Purchase",
    color: "#FEF6D6",
    shadow: "shadow-gray-200",
    percentage: -5.0,
  },
  {
    icon: salesIcon,
    value: "190+",
    label: "Total Products",
    color: "#FDEDEB",
    shadow: "shadow-gray-200",
    percentage: 10.5,
  },
  {
    icon: jobIcon,
    value: "12+",
    label: "Total Stores",
    color: "#E6EAFE",
    shadow: "shadow-gray-200",
    percentage: 2.0,
  },
];

const CardGrid = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
      {cardData.map((card, index) => (
        <Card
          key={index} // Pass props
          icon={card.icon}
          value={card.value}
          label={card.label}
          color={card.color}
          shadow={card.shadow}
          percentage={card.percentage}
        />
      ))}
    </div>
  );
};

export default CardGrid;
