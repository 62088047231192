import React from "react";

const Card = ({ icon, value, label, color, shadow, percentage }) => {
  // Determine badge color based on percentage
  const badgeColor =
    percentage >= 0 ? "bg-green-100 text-green-600" : "bg-red-100 text-red-600";

  return (
    <div
      className={`relative w-full p-6 h-30 rounded-[10px] shadow-md ${shadow}`}
      style={{ backgroundColor: "#ffffff", minHeight: "150px" }} // setting a minimum height ( Check when making responsive for mobile devices)
    >
      {/* Percentage Badge */}
      <div
        className={`absolute top-2 right-2 inline-flex gap-2 rounded ${badgeColor} p-1`}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-4 w-4"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6"
          />
        </svg>
        <span className="text-xs font-medium">{percentage}%</span>
      </div>
      <div className="flex items-center justify-between h-full">
        <div className="flex items-center">
          <div className="p-4 rounded-full" style={{ backgroundColor: color }}>
            <img src={icon} alt={label} className="w-6 h-6" />
          </div>
          <div className="ml-4">
            <h2 className="text-xl font-extrabold text-black opacity-70">{value}</h2>
            <p className="text-sm font-normal text-fontColor opacity-70">
              {label}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
