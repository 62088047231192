import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

const DonutGraph = () => {
  const data = {
    labels: ["Sale", "Distribute", "Return"],
    datasets: [
      {
        label: "Inventory Distribution",
        data: [300, 150, 100], // Example values, replace with actual data
        backgroundColor: [
          "#36A2EB", // Blue
          "#FFCE56", // Yellow
          "#ff2345", // Dark Red
        ],
        hoverOffset: 4,
        borderRadius: 8, // Round the edges of the segments
        borderWidth: 2, // Thickness of the border for emphasis
        cutout: "60%", // Adjust inner circle size to create a donut effect
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "bottom",
        labels: {
          padding: 20, //bottom labels (legends)
          font: {
            family: "'Nunito', sans-serif",
            size: 14,
            weight: 600,
            lineHeight: "21.82px",
          },
          color: "rgba(3, 2, 41, 0.7)", // 70% opacity of #030229
          usePointStyle: true, // Use a circular legend icon
          pointStyle: "circle", // Circle legend indicator
          boxWidth: 10, // Adjust size of the point style
        },
      },
      title: {
        display: true,
        text: "Analytics", //title
        font: {
          size: 18,
          family: "'Nunito', sans-serif",
          weight: 700,
          lineHeight: "24.55px",
        },
        color: "rgba(3, 2, 41, 0.7)", // 70% opacity of #030229
        padding: {
          top: 2,
          bottom: 20,
        },
        align: "start", // Align title to the left
      },
    },
    animation: {
      animateScale: true, // Add a scaling effect to make the chart smoother
      animateRotate: true, // Add rotation to animate the doughnut chart
    },
  };

  return (
    <div
      className="w-full bg-componentBackground shadow-sm rounded-[10px] p-6 font-nunito"
      style={{
        height: "400px",
        border: "1px solid rgba(91, 147, 255, 0.05)", // Using TailwindCSS border
      }}
    >
      <Doughnut data={data} options={options} />
    </div>
  );
};

export default DonutGraph;
